export default [
  {
    title: 'No',
    slots: { customRender: 'no' },
    align: 'center',
    // dataIndex: 'index',
  },
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   align: 'center',
  // },
  {
    title: 'Nama Organisasi',
    dataIndex: 'name',
    align: 'center',
  },

  {
    title: 'Level Organisasi',
    dataIndex: 'level',
    align: 'center',
  },

  {
    title: 'Struktur Organisasi',
    dataIndex: 'description',
    align: 'center',
  },

  {
    title: 'Status',
    dataIndex: 'status_text',
    // slots: { customRender: 'status' },
    align: 'center',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]
