<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a-select
          :options="[
            { value: null, label: 'Semua' },
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          class="mr-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="status"
        ></a-select>
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="ml-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-2"
          title="Tambah Organisasi"
          type="primary"
          @click="
            () => {
              formState = {}
              modal1Visible = true
              formState.title_state = 'Tambah Entitas'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <a-button
        type="primary"
        title="generate organization"
        :loading="fecthGenerate"
        >Generate Organization</a-button>

        <a-button
          class="ml-2"
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="table-responsive ">
      <md-table
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #status="{ record }">
          <a-tag v-if="record.status === 0" color="#108ee9">Aktif</a-tag>
          <a-tag v-else color="grey">Tidak Aktif</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Area">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="lihat(record.id)"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Area">
              <a
                href="javascript: void(0);"
                class="btn  btn-sm btn-light"
                @click="edit(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus Area">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="postDelete(record.id)"
              >
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <a-modal
      v-model:visible="modal1Visible"
      :title="formState.title_state"
      width="700px"
      :destroy-on-close="true"
      :mask-closable="false"
      @ok="modal1Visible = false"
    >
      <template #footer>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        ></template
      >
      <a-form ref="formRef" :wrapper-col="{ span: 14 }" class="myform" :model="formState" :rules="rules">
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Nama Organisasi"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              name="name"
            >
              <a-input width="300px" v-model:value="formState.name"  :disabled="formState.readOnly" required/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item
          label="Pilih Entitas"
          label-align="left"
          :label-col="{ sm: { span: 6 } }"
          :wrapper-col="{ sm: { span: 24 - 6 } }"
        >
          <a-col :sm="{ span: 15}">
            <a-row class="myform-vertical" layout="vertical" v-if="type">
              <template v-for="(item, index) in type.filter(i => !i.is_deleted)" :key="index">
                <a-input
                  size="small"
                  type="hidden"
                  v-model:value="item.id"
                  :disabled="formState.readOnly || item.id !== null"
                />
                <a-row class="form-row" :gutter="[8, 8]">
                  <a-col>
                    <a-form-item
                      label-align="left"
                      :wrapper-col="{ sm: { span: 24 } }"
                      :name="['type', index, 'types']"
                      :help="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'types')
                            ? item.errors.find(i => i.field === 'types').message
                            : undefined
                          : undefined
                      "
                      :validate-status="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'types') !== null
                            ? 'error'
                            : undefined
                          : undefined
                      "
                    >
                      <a-select v-model:value="item.types" style="width: 120px"
                        :disabled="formState.readOnly || item.id !== null"
                        placeholder="Pilih Entitas"
                        :mode="null"
                      >
                      <a-select-option :key="1">Distributor</a-select-option>
                        <a-select-option :key="2">Toko</a-select-option>
                        <a-select-option :key="1105">Large Toko</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item
                      :wrapper-col="{ sm: { span: 24 } }"
                    >
                      <a-input style="width: 120px" :value="'Level '+(index+1)"  disabled />
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item label="" label-align="left">
                      <a-button
                        @click="removeRow(item)"
                        size="small"
                        type="danger"
                        style="margin-top: 12px"
                        :wrapper-col="{ sm: { span: 24 } }"
                        :disabled="formState.readOnly"
                        ><i class="fa fa-remove"></i
                      ></a-button>
                    </a-form-item>
                  </a-col>
                </a-row>
              </template>
            </a-row>
            <!-- add function -->
            <template v-if="!formState.readOnly">
              <a-form-item
                label-align="left"
                :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
                :colon="false"
              >
                <a-button v-if="type.length<3" type="primary" @click="addCode"
                  ><i class="fa fa-plus"></i> Tambah</a-button
                >
              </a-form-item>
            </template>
          </a-col>
        </a-form-item>



        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Status"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
            >
              <a-radio-group v-model:value="formState.status" :disabled="formState.readOnly">
                <a-radio :value="1">Aktif</a-radio>
                <a-radio :value="0">Tidak Aktif</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent, ref, watch, onMounted,
  reactive,
  onBeforeMount,
  provide,
  toRef,
  toRefs,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import acolumns from './columns'
import qs from 'qs'

const initial = {
  id: null,
  name:null,
  types: [],
  level: null,
  readOnly: false,
}

export default defineComponent({
  name: 'VbPemetaanOrganisasi',
  components: {},
  setup() {
    const formRef = ref()
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const loading = ref(false)
    const modal1Visible = ref(false)
    const formState = ref({
      name: null,
      types: [],
      level: [],
      readOnly: false,
      status: null,
    })
    const data = ref([])
    const q = ref('')
    const status = ref(null)
    const isPosting = ref(false)
    const value1 = ref(true)
    const value2 = ref(false)
    const errorMessage = ref(null)
    const visible = ref(false)
    columns.value = acolumns
    const params = ref({})
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })
    const startRow = ref(1)
    const type = toRef(formState.value, 'type')
    const types = ref({
      id: null,
     name: null,
     types: null,
     level: null,
    })

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        q: q.value,
        status: status.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/organizations', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/organizations', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title, index }) => ({ key, title, index })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `organizations_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const lihat = id => {
      apiClient
        .get(`/api/organizations/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Lihat Organizations'
          formState.value.readOnly = true
          formState.value.types = formState.value.type.map(v => ({
            ...v,
            name: `${v.type} - ${v.level}`,
          }))
          formState.value.types = formState.value.type.map(v => v.id)
        })
        .catch(error => console.error(error))
    }

    const edit = id => {
      apiClient
        .get(`/api/organizations/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Edit Organizations'
          formState.value.state = 'edit'
          formState.value.readOnly = false
          formState.value.types = formState.value.type.map(v => ({
            ...v,
            name: `${v.type} - ${v.level}`,
          }))
          formState.value.types = formState.value.type.map(v => v.id)
        })
        .catch(error => console.error(error))
    }

    const submit = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi ',
          content: 'Apakah anda ingin melanjutkan tambah entitas ?',

          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            form_data.append('level', 1)
            const data_type = ref([])
            const data_level = ref([])
            for (var key in type.value) {
              data_type.value.push(type.value[key].types)
              data_level.value.push(key+1)
            }
            form_data.append('types', data_type.value)
            apiClient
              .post('/api/organizations', form_data)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan!'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Ubah entitas',
          content: 'Apakah anda ingin melanjutkan edit entitas ?',
          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            // TODO: validasi
            form_data.append('level', 1)
            const data_type = ref([])
            const data_level = ref([])
            for (var key in type.value) {
              data_type.value.push(type.value[key].types)
              data_level.value.push(key+1)
            }
            form_data.append('types', data_type.value)
            apiClient
              .post('/api/organizations/' + formState.value.id + '?_method=PUT', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan!'))
              .finally(e => {
                isPosting.value = false
              })
          },
          onCancel() {},
        })
      }
    }

    const postDelete = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus',
        content: 'Apakah anda ingin Hapus organisasi ?',
        onOk() {
          apiClient
            .delete(`/api/organizations/${id}`)
            .then(response => {
              const data = response.data
              formState.value = data
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal mengupdate!'))
        },
        onCancel() {},
      })
    }

    const search = () => {
      fetchData()
    }

    onMounted(() => {
      fetchData()
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }

    onBeforeMount(() => {
      if (formState.value.type === undefined || formState.value.type === null) {
        formState.value.type = []
      }
    })

    const addCode = () => {
      if (type.value.filter(v => v.types === null).length > 0) {
        message.warning('Pastikan Entitas terisi!')
        return
      }
      // isAddCode.value = false
      const m = Object.assign({}, types.value)
      // m.id = 1
     type.value.push(m)
      // resetCode()
    }

    const removeRow = item => {
      const index = type.value.indexOf(item)
      if (item.id === null) type.value.splice(index, 1)
      else {
        item.is_deleted = true
      }
    }

    return {
      submit,
      types,
      type,
      addCode,
      removeRow,
      formRef,
      modal1Visible,
      loading,
      visible,
      handleOk,
      formState,
      setModal1Visible,
      data,
      columns,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      searchText,
      searchInput,
      fetchXlsx,
      q,
      search,
      startRow,
      state,
      ...toRefs(state),
      fetchData,
      handleTableChange,
      // modalRef,
      handleReset,
      handleSearch,
      onSelectChange,
      status,
      isPosting,
      lihat,
      edit,
      postDelete,
      value1,
      value2,
      errorMessage,
    }
  },
})
</script>
<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector),
.myform :deep(.ant-radio-disabled + span) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
</style>
